@import url('https://fonts.googleapis.com/css?family=Baloo+Bhai|Kulim+Park|Oswald|Questrial|Roboto|Roboto+Condensed&display=swap');

/* font-family: 'Baloo Bhai', cursive;
font-family: 'Questrial', sans-serif;
font-family: 'Kulim Park', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Roboto Condensed', sans-serif; */

.App {
  min-width: 100vw;
  position: absolute;
  top: 64px;
  
  
  
  
}

.parallax{
  background-image: url('https://images.unsplash.com/photo-1469820578517-4086c8a154df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1708&q=80');
  /* background-image: url('https://images.unsplash.com/photo-1534142499731-a32a99935397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80'); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 64px;
  z-index: -1;
}

.shadow{
  -webkit-box-shadow: 0px 0px 22px 5px rgba(89,89,89,0.53); 
box-shadow: 0px 0px 22px 5px rgba(89,89,89,0.53);
}

#root{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom right, rgb(249, 255, 221),rgb(114, 173, 228), rgb(255, 255, 255));

}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-row{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.even{
  justify-content: space-evenly;
}

.apart{
  justify-content: space-between;
}

.cursor{
  cursor: pointer;
}

.main-pic{
  height: 300px;
  width: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  /* border: solid 1px green; */
  background-position: center;
  margin: 16px;
}

.header{
  width: 100%;
  height: 64px;
  background-image: linear-gradient(to bottom right, rgb(0, 8, 83), rgb(3, 0, 168));
  color: rgb(228, 0, 0);
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 3;
  
}

.header-title{
  font-family: 'Baloo Bhai', cursive;
  font-size: 5vw;
  color: rgb(255, 235, 51);
  margin-right: 16px;
  padding: 0 4px;
  text-align: center;
  /* width: 50%; */
}

.tab{
  transition: ease .5s;
  text-decoration: none;
  color: rgb(228, 0, 0);
  padding: 0 8px;
  text-align: center;
  
}

.tab:hover{
 color: antiquewhite; 
  transition: ease .5s;
}

.tab-name{
  color: inherit;
  font-size: 1rem;
}

.story-text{
  font-family: 'Roboto', sans-serif;
  color: rgb(0, 30, 112);
  background-color: rgba(232, 236, 255, 0.671);
  padding: 16px;
  width: 80%;
  max-width: 600px;
  font-size: 1.2em;
}

.mosaic{
  width: 300px;
  border: black solid 1px;
  flex-wrap: wrap;
}

.mosaic-item{
  width: 50%;
  height: 200px;
  /* margin: 5px; */
  background-size: cover;
  background-position: center;
  transition: ease .5s;


}

.mosaic-item:hover
{
  transform: scale(1.1);
  transition: ease .5s;
  z-index: 1;

}

.banner{
  width: 90%;
  font-size: 1rem;
  background-image: linear-gradient(white, grey);
  padding: 32px;
}

.comments{
  max-width: 600px;
  width: 90%;
  padding: 32px;
  /* background-color: coral; */
}
.comment{
  width: 98%;
  background-image: linear-gradient(to bottom right, rgb(35, 32, 179),rgb(102, 100, 241));
  margin: 8px;
  padding: 8px;
  border-radius: 5px;

}

.comment-user{
  /* background-color: cornflowerblue; */
  color: aliceblue;
  padding: 0px;
  margin-bottom: 8px;


}

.comment-body{
  background-color: aliceblue;
  padding: 16px;
  width: 95%;
}

.add-comment{
  background-color: rgba(0, 0, 0, 0.664);
  max-width: 600px;
  width: 90%;
  padding: 16px;
}

.title{
  color: rgb(255, 40, 40);
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 16px;
}

.btn{
  background-color: white;
  width: 64px;
  height: 32px;
  
}

.btn:hover{
  background-color: rgb(169, 192, 255);
  cursor: pointer;
}

#textarea{
  width: 80%;
  margin: 8px;
}

.footer{
  width: 100%;
  height: 128px;
  background-image: linear-gradient(to bottom right, rgb(0, 8, 83), rgb(3, 0, 168));
  margin-top: 16px;
}